/* eslint-disable camelcase */

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

const en = {
  demo: {
    title: `English`,
    introduction: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`,
  },
  docs: {
    hi: `Hello`,
    description: `Please check our docs.`,
    documentation: `Documentation`,
    contact_us: `Contact us`,
    starleader_values: `StarLeader values`,
    update_your_cookies_settings: `Update your cookies settings`,
    cookiePolicy: `Cookie policy`,
    legalNotice: `Legal notice`,
    privacyPolicy: `Privacy policy`,
  },
  menu: {
    Home: `Home`,
    Summary: `Summary`,
    Reviews: `Reviews`,
    Goals: `Goals`,
    Campaigns: `Campaigns`,
    Analytics: `Analytics`,
    Admin: `Admin`,
  },
  home_page: {
    no_reviews_yet: `No reviews yet. Ask your employees to review you.`,
    step_1: `Manager Self-Review, Employee/Peer Review`,
    step_2: `Set one goal and 3 actions by manager after discussion with N+1 and team ideally`,
    step_3: `Execute 3 actions by manager`,
    step_4: `End of campaign`,
    welcome_msg: `Welcome`,
    welcome_text: `We are here to support you every step of the way. Let's make this journey successful together!`,
    campaign_step: `Step-by-Step campaign guide`,
    no_campaign: `No campaign available. Contact your HR to create one and start reviewing!`,
    click_here: `Click here`,
    create_one: `to create one.`,
    hr_home_page_intro_1: `Nothing to do here for now. Visit`,
    hr_home_page_intro_2: `the analytics page`,
    hr_home_page_intro_3: `for some stats, or`,
    hr_home_page_intro_4: `the campaign page`,
    hr_home_page_intro_5: `to see details of the current campaign`,
  },
  reviews_page: {
    no_self_reviews_yet: `You have no self reviews yet.`,
    no_reviews_yet: `You have no reviews yet.`,
    no_reviews_visible: `Your reviews will be visible at the end of campaign's step review`,
    my_reviews: `My reviews`,
    self_reviews: `Self-Reviews`,
    submitted_reviews: `Submitted Reviews`,
  },
  survey_summary: {
    self_review: `Self-Review`,
    review: `Review`,
    peer_review: `Peer-Review`,
    recommendation: `Recommandation`,
    trust: `Trust`,
    open_review: `open review`,
    resume_review: `resume review`,
  },
  categories_tooltip: {
    care: `VISION: envisions a desired future and generates excitement by conveying a compelling, shared vision`,
    coach: `COMMUNICATION: communicates well and frequently provides explanations to stakeholders`,
    develop: `DEVELOPMENT: fosters a development culture by encouraging all team members to embrace a growth mindset and taking actions daily to develop themselves and those around them`,
    deliver: `TRUST: influences others by building credibility and positive relationships rather than relying on authority to generate momentum around Shape priorities`,
    lead: `PROBLEM SOLVING: continuous improvement and the use of proven, problem-solving methodologies`,
  },
  reviews_one_pager: {
    manager_reaction: `manager's reaction:`,
    comment: `comment:`,
  },
  survey: {
    comment_label: `Add your comment (Mandatory)`,
    comment_tooltip: `Please be constructive and respectfull. Any racisst, sexist, discriminatory or offensive content will be removed. Use the most neutral language possible to maintain anonymity. Upon notification from a third party, we may remove any inappropriate content.`,
    submit: `Submit`,
    start_survey: `Hey! It will take you 10 minutes to complete this survey that is anonymous. Be as authentic and constructive as possible.`,
    survey_end: `Congrats on your participation! Your review is submitted. Let's have a look in the summary`,
    rating1: `Oops, It appears that your manager has room for improvement in this behavior... What action would you recommend to your manager to improve?`,
    rating5: `Wow! Your manager seems to excel in this behavior. It would be great to let your manager know by providing a concrete example. This will surely be appreciated!`,
    click_icon_bellow: `Click on the icon bellow to continue`,
    click_star: `Click on a star rating to continue`,
    add_comment: `Add your comment here (Optional, except for 1 and 5 stars rating)`,
  },
  discuss_chat: {
    discuss: `Discuss`,
    chat_tooltip: `Open the discussion window. If new message received a notification will be send.`,
    chat_label: `Type your message...`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  blog: `blog`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  posts: `posts`,
  create: `create`,
  kanban: `kanban`,
  general: `general`,
  banking: `banking`,
  booking: `booking`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  ecommerce: `e-commerce`,
  management: `management`,
  menu_level: `menu level`,
  menu_level_2a: `menu level 2a`,
  menu_level_2b: `menu level 2b`,
  menu_level_3a: `menu level 3a`,
  menu_level_3b: `menu level 3b`,
  menu_level_4a: `menu level 4a`,
  menu_level_4b: `menu level 4b`,
  item_disabled: `item disabled`,
  item_label: `item label`,
  item_caption: `item caption`,
  item_external_link: `item external link`,
  description: `description`,
  other_cases: `other cases`,
  item_by_roles: `item by roles`,
  only_admin_can_see_this_item: `Only admin can see this item`,
  reviews: `reviews`,
  review: `review`,
  self_review: `self-review compared to your team`,
  campaign: `campaign`,
  me: `Me`,
  other_managers: `Other managers`,
  recommend_you: `Recommend you`,
  trust_you: `Trust you`,
  employee: `collaborateur`,
  manager: `manager`,
  hr: `HR`,
  summary: `Summary`,
  rating_answer: `rating answers`,
  from_your_team: `from your team`,
  from_your_peers: `from your peers`,
  comparaison: `comparaison`,
  no_results_yet: `No results yet.`,
  campaign_page: {
    campaign: `Campaign`,
    set_goal: `Setting goals`,
    review_period: `Reviewing period`,
    one_campaign: `There is already a campaign in progress. Only one campaign can be active at this time.`,
    no_campaign: `There are no campaigns currently.`,
    create_campaign: `Create new campaign`,
    start_campaign: `Start`,
  },
  values_page: {
    title: `Rome was not built in a day but burnt in a night`,
    author: ``,
    section1: `Relation between manager and subordinate is key in our
    professionnal life. Whether you are an employee or a manager,
    it is in everyone's interest to place the quality of this
    relationship at the heart of our daily interactions.`,
    section2: `As a subordinate, StarLeader gives you the opportunity to
    express yourself anonymously and constructively about your
    manager's behaviour. Your opinion matters!`,
    section3: `Become a change agent by helping your manager to become better
    version of himself, that's the unique experience we offer
    you with StarLeader.`,
    section4: ` As a manager, StarLeader is a tool to improve your self
    awareness, progress and develop trust within your team! Become
    a better StarLeader and give yourself more chances to grow
    within the company, this is the unique experience we offer you
    offer you with StarLeader.`,
    section5: `By making leadership quality a new indicator of success,
    StarLeader aims to combine sustainable performance with team
    well-being.`,
  },
  continue: `Continue`,
  privacy_page: {
    title: `Privacy Policy`,
    section1: `At StarLeader, protecting your privacy is a priority.`,
    section2: `When you use the www.starleader.io website (the
      "Site"), we may collect personal data about
      you.`,
    section3: `The purpose of this policy is to inform you about how we
    process such data in compliance with Regulation (EU) 2016/679
    of 27 April 2016 on the protection of individuals with regard
    to the processing of personal data and on the free movement of
    such data (the "GDPR").`,
    section4: `1. Who is the data controller?`,
    section5: `While you are browsing our Website, the data controller is
    Alphayz, a S.A.S company registered in the Nanterre Trade and
    Companies Register under number 952 853 109 and whose
    registered office is located at 33 rue Benoit Malon 92800
    Puteaux ("We")`,
    section6: `On the other hand, when our customers use our services, we
    collect and process personal data on their behalf and for
    their account. Our customers are therefore data controllers in
    accordance with Article 4 of the GDPR. We act as a processor,
    as a service provider.`,
    section7: `2. What data do we collect?`,
    section8: `Personal data is data that can be used to identify an
    individual directly or by cross-checking with other data.`,
    section9: `We collect personal data in the following categories:`,
    section10: `Identification data `,
    section11: `(name, first name, professional email address);`,
    section12: `Data related to your professional life `,
    section13: `(company name, position / function);`,
    section14: `Connection data `,
    section15: `(connection logs, encrypted passwords);`,
    section16: `Browsing data `,
    section17: `(IP address, pages viewed, date and time of connection,
      browser used, operating system, user ID, IFA);`,
    section18: `Location data. `,
    section19: `3. On what legal grounds, for what purposes and for how
    long do we keep your personal data?`,
    section20: `Objectives`,
    section21: `Legal basis`,
    section22: `Shelf life`,
    section23: `To provide our services available on our Site`,
    section24: `Execution of pre-contractual measures taken at your
    request and/or execution of the contract that you or your
    company have signed with Us`,
    section25: `When you have created an account: your data is kept for
    the duration of your account. Your connection logs are
    kept for one (1) year. If your account is inactive for 2
    years, your personal data will be deleted if you do not
    respond to our reactivation email`,
    section26: `Improve our services`,
    section27: `Our legitimate interest in improving our services`,
    section28: `Personal data are kept for one (1) year`,
    section29: `Send newsletters, solicitations and promotional messages`,
    section30: `For customers: our legitimate interest in building
    customer loyalty and keeping customers informed of our
    latest news For prospects: your consent`,
    section31: `The data is kept for 3 years from your last contact with Us`,
    section32: `Respond to your information requests`,
    section33: `Our legitimate interest in responding to your requests`,
    section34: `The data is kept for the time necessary to process your
    request for information and deleted once the request for
    information has been processed`,
    section35: `To draw up statistics (of navigation, of the audience of
      the Site, etc.) and to improve the functionalities of the
      Site through the deposit of cookies of audience
      measurement`,
    section36: `Our legitimate interest in analyzing the composition of
    our customer base and improving our services`,
    section37: `The data is kept for twenty five (25) months`,
    section38: `Manage requests to exercise rights`,
    section39: `Our legitimate interest in responding to your requests and
    keeping track of them`,
    section40: `If we ask you for proof of identity, we will only keep it
    for as long as it takes to verify your identity. Once the
    verification is complete, the proof of identity is
    deleted.`,
    section41: `4. Who are the recipients of your data?`,
    section42: `Will have access to your personal data:`,
    section43: `The staff of our company;`,
    section44: `Our subcontractors: hosting provider, audience
    measurement and analysis provider, e-mail provider;`,
    section45: `If applicable: public and private organizations,
    exclusively to meet our legal obligations;`,
    section46: `5. Will your data be transferred outside the European
    Union?`,
    section47: `Your data is kept and stored for the duration of the treatment
    on the servers of the company Heroku, located in the European
    Union.`,
    section48: `6. What are your rights to your data?`,
    section49: `You have the following rights with respect to your personal
    data:`,
    section50: `Right to information: `,
    section51: `this is precisely the reason why we have drafted this policy.
    This right is provided for in articles 13 and 14 of the RGPD.`,
    section52: `Right of access: `,
    section53: `you have the right to access all your personal data at any
    time, in accordance with Article 15 of the GDPR.`,
    section54: `Right of rectification: `,
    section55: `you have the right to rectify your inaccurate, incomplete or
    outdated personal data at any time in accordance with Article
    16 of the GDPR.`,
    section56: `Right to limitation: `,
    section57: `you have the right to obtain the limitation of the processing
    of your personal data in certain cases defined in Article 18
    of the GDPR.`,
    section58: `Right to erasure: `,
    section59: `you have the right to request that your personal data be
    erased, and to prohibit future collection of your personal
    data on the grounds set out in Article 17 of the GDPR.`,
    section60: `Right to lodge a complaint with a competent supervisory
    authority`,
    section61: `(in France, the CNIL), if you consider that the processing of
    your personal data constitutes a violation of the applicable
    texts, in accordance with Article 77 of the GDPR`,
    section62: `The right to set up instructions for the retention, deletion
    and disclosure of your personal data after your death.`,
    section63: `Right to withdraw your consent at any time: `,
    section64: `for purposes based on consent, Article 7 of the GDPR states
    that you may withdraw your consent at any time. Such
    withdrawal will not affect the lawfulness of the processing
    carried out before the withdrawal.`,
    section65: `Right to portability: `,
    section66: `under certain conditions specified in Article 20 of the GDPR,
    you have the right to receive the personal data you have
    provided to us in a standard machine-readable format and to
    request its transfer to the recipient of your choice.`,
    section67: `Right to object: `,
    section68: `under Article 21 of the GDPR, you have the right to object to
    the processing of your personal data. Note, however, that we
    may continue to process them despite this objection, for
    legitimate reasons or the defense of legal rights.`,
    section69: `You can exercise these rights by writing to us at the address
    below. We may ask you to provide additional information or
    documents to prove your identity.`,
    section70: `7. What cookies do we use?`,
    section71: `To learn more about how we manage cookies we invite you to
    consult our`,
    section72: `8. Contact point to exercise your rights`,
    section73: `Contact email: support@starleader.io.`,
    section74: `Contact address : 33 rue Benoit Malon 92800 Puteaux FRANCE.`,
    section75: `9. Changes`,
    section76: `We may modify this policy at any time, in particular to comply
    with any regulatory, legal, editorial or technical
    developments. These changes will apply as of the effective
    date of the modified version. You are therefore invited to
    regularly consult the latest version of this policy.
    Nevertheless, we will keep you informed of any significant
    changes to this Privacy Policy.`,
    section77: `Effective date: June 06, 2023`,
  },
  cookies_page: {
    title: `Cookie Policy`,
    section1: `1. What is a Cookie?`,
    section2: `During your navigation on the site www.starleader.io, cookies,
    pixels and other tracers are deposited on your browser.`,
    section3: `A cookie is a small file, often encrypted, stored in your
    browser or your terminal and identified by a name. It is
    deposited when you visit a site. Each time you return to the
    site in question, the Cookie is retrieved from your browser or
    terminal. Thus, each time you visit the site, the browser is
    recognized.`,
    section4: `2. Identification of Cookies`,
    section5: `We may use technical and audience measurement Cookies. You
    will be informed of this when you first visit the Site using
    these Cookies. You will then be asked to accept or decline
    them in the manner described below.`,
    section6: `Technical and functional cookies`,
    section7: `Technical and functional cookies are necessary for the proper
    functioning of the Site and to provide you with our services.
    They are used throughout your navigation, in order to
    facilitate it and to execute certain functions.`,
    section8: `A technical Cookie may, for example, be used to remember your
    responses to a form or your preferences regarding language or
    the layout of the Site, where such options are available.`,
    section9: `Cookies for audience measurement`,
    section10: `These cookies, also known as "analytical cookies",
    allow us to measure the number of visits, the number of pages
    viewed and user activity. They may collect your IP address to
    the city from which you are connecting. Cookies for audience
    measurement allow us to generate statistics on the number of
    visits and navigation of our Site in order to improve our
    performance. The cookies used also allow us to identify
    navigation problems and eventually solve them.`,
    section11: `3. Your cookie preferences`,
    section12: `Cookies that can be deposited without consent`,
    section13: `Some cookies do not require your consent, such as:`,
    section14: ` Technical and functional cookies that are necessary
    for the operation of the Site;`,
    section15: ` Certain audience measurement cookies or cookies that
    that allow us to test different versions of the Site for the
    purpose of optimizing editorial choices;`,
    section16: `Acceptance or refusal of Cookies subject to your express
    consent`,
    section17: `All other Cookies require your consent. These are advertising
    cookies, social networking cookies, content personalization
    cookies and some audience measurement cookies. You can freely
    choose to accept or refuse the use of these Cookies.`,
    section18: `You can accept or reject these cookies when you first visit
    the Site.`,
    section19: `Your choices to accept or decline Cookies will be retained for
    a period of six (6) months.`,
    section20: `You are free to withdraw your consent and more generally to
    modify your preferences at any time, via the cookies banner
    link`,
    section21: `Setting up your browser`,
    section22: `It is also possible to set your browser to accept or reject
    certain cookies.`,
    section23: `Each browser offers different configuration methods.`,
  },
  legal_page: {
    title: `Legal Notice`,
    section1: `Publisher:`,
    section2: `The www.starleader.io website is published by: `,
    section3: `The company Alphayz `,
    section4: `S.A.S with a share capital of 5.000,00 euros`,
    section5: `Registered at the Registry of Trade and Companies of Nanterre
    under the number 952 853 109`,
    section6: `Registered office: 33 rue Benoit Malon 92800 Puteaux FRANCE`,
    section7: `Intra-community VAT number: FR88952853109 `,
    section8: `Telephone number: +33 6 64 44 59 06`,
    section9: `Email address: support@starleader.io `,
    section10: `Director of the publication:`,
    section11: `Ms. Laura Goddard, President of the company Alphayz`,
    section12: `Hosting provider:`,
    section13: `Company: Salesforce Sarl (Heroku.Inc is a Salesforce company)`,
    section14: `Registered office: Route de la Longeraie 9, Morges, 1110,
    Switzerland, attn: Director, EMEA Sales Operations, with a
    copy to attn.: Legal Department - Service Juridique, 3 Avenue
    Octave Gréard, 75007 Paris, France.`,
    section15: `The Heroku servers used by starleader.io are situated in the
    European Union.`,
  },
  goals_page: {
    title: `Goals`,
    target: `Target`,
    completed: `Completed`,
    complete: `Complete`,
    todo: `To do`,
    inprogress: `In progress`,
    done: `Done`,
    no_reviews: `There are still no reviews submitted for you. Ask your employees to fill out the survey and check back later.`,
    no_reviews_1: `There are no reviews for you yet. Ask your employees`,
    no_reviews_2: `to review you`,
    no_reviews_3: `and check back. Review scores are important for a constructive goal.`,
    one_goal: `You can create only one goal`,
    create_new_goal: `Create new goal`,
    set_score: `Set your desired score for next campaign:`,
    pick_rating: `Pick a rating higher than current`,
    pick_score: `Pick a score higher than current`,
    achieve_improvement: `Achieve improvement in:`,
    one_month: `One month`,
    two_months: `Two months`,
    three_months: `Three months`,
    define_actions: `Define your 3 actions:`,
    pick_action: `Pick an action from the left or type in custom`,
    chose_category: `Choose a category you want to improve in`,
    chose_behavior: `Choose the behavior you want to improve in`,
  },
  ratingDescription: {
    1: `Strongly disagree`,
    2: `Disagree`,
    3: `Neutral`,
    4: `Agree`,
    5: `Strongly agree`,
  },
  reactions: {
    1: `Thank you`,
    2: `Love it`,
    3: `Disagree`,
  },
};

export default en;
